/*=========================================================================================
  File Name: moduleSurgeryActions.js
  Description: Surgery Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
    AddSurgery({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/Surgery/AddSurgery", item)
                .then((response) => {
                    commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetAllSurgeries({ commit }) {
        debugger
        return new Promise((resolve, reject) => {
            axios.get("api/Surgery/GetAllSurgeries")
                .then((response) => {
                    debugger
                    commit('SET_Surgery', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    
    getNotPublishSurgeries(context) {
        debugger
        return new Promise((resolve, reject) => {
            axios.get("api/Surgery/getNotPublishSurgeries")
                .then((response) => {                    
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },


    // GetAllSurgeries({ commit }) {
    //     debugger
    //     return new Promise((resolve, reject) => {
    //         axios.get("api/Surgery/GetSurgeryIncludedItems?ID=" + 1)
    //             .then((response) => {
    //                 commit('SET_surgeryIncludedItem', response.data.Data)
    //                 commit('SET_SurgeryItem', response.data.Data)
    //                 resolve(response)
    //             })
    //             .catch((error) => { reject(error) })
    //     });
    // },
    GetSurgeryIncludedItems({ commit }, itemid) {
        if (itemid == undefined)
            itemid = 0;
        return new Promise((resolve, reject) => {
            axios.get("api/Surgery/GetSurgeryIncludedItems?ID=" + itemid)
                .then((response) => {
                    commit('SET_surgeryIncludedItem', response.data.Data)
                    commit('SET_SurgeryItem', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    publishSurgery({ commit }, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios.post("api/Surgery/UpdateSurgery", item)
                .then((response) => {
                    commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    UpdateSurgery({ commit }, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios.post("api/Surgery/UpdateSurgery", item)
                .then((response) => {
                    commit('UPDATE_Surgery', item)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetSurgery({ commit }, itemid) {
        return new Promise((resolve, reject) => {
            axios.post("api/Surgery/GetSurgery", itemid)
                .then((response) => {
                    commit('UPDATE_Surgery', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetLockupSurgery(context, itemid) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/Surgery/GetSurgery?ID=" + itemid)
                .then(response => {
                    resolve(response);
                    // commit("REMOVE_ITEM", itemid);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DeleteSurgery({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.delete("api/Surgery/DeleteSurgery?ID=" + item.ID)
                .then((response) => {
                    commit('REMOVE_ITEM', item.ID)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
}