/*=========================================================================================
  File Name: moduleSurgeryMutations.js
  Description: Surgery Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    ADD_ITEM(state, item) {
        state.surgeries.unshift(item)
    },
    SET_Surgery(state, surgeries) {
        state.surgeries = surgeries
    },
    UPDATE_Surgery(state, Surgery) {
        debugger
        if (state.surgeries.length > 0) {
            const SurgeryIndex = state.surgeries.findIndex((p) => p.ID == Surgery.ID)

            Object.assign(state.surgeries[SurgeryIndex], Surgery)
        }
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.surgeries.findIndex((p) => p.ID == itemId)
        state.surgeries.splice(ItemIndex, 1)
    },
}